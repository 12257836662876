import { LoaderService } from './loader.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';



@NgModule({
  declarations: [LoaderComponent],
  imports: [
    CommonModule,
  ], 
  exports: [
    LoaderComponent
  ],
  providers: [
    LoaderService
  ]
  
})
export class LoaderModule { }
