<div class="footer-info">
  <h2 class="font-bold">NEWSLETTER</h2>
  <p>
    Aboneaza-te la newsletterul KXL pentru a putea fi la curent cu cele mai noi
    si interesante proiecte.
  </p>
  <form [formGroup]="newsletterForm">
    <div class="form-group row newsletter-form">
      <label for="inputEmail3" class="col-lg-4 col-md-12 col-sm-12 col-form-label">Adresa de email*</label>
      <div class="col-lg-4 col-md-12 col-sm-12">
        <input type="email" formControlName="email" />
      </div>
      <!-- </div> -->

      <!-- <div class="form-group row"> -->
      <div class="col-lg-4 col-md-12 col-sm-12 send-email">
        <button class="btn btn-outline-success" [hidden]="!newsletterForm.valid" (click)="sendEmail()">
          Trimite
        </button>
      </div>
    </div>
  </form>
</div>