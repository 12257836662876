  <nav class="navbar navbar-expand-md navbar-dark fixed-top">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="../../../assets/images/logo.png" class="img-fluid" alt="">
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse navbar-toggleable-sm" [ngClass]="{ 'show': navbarOpen }" id="navbarNav">

        <button (click)="toggleNavbar()" class="navbar-toggler ml-auto navbar-close" type="button">
          <i class="fas fa-times"></i>
        </button>

        <ul class="navbar-nav">
          <li class="nav-item" *ngFor="let item of menusList">
            <a class="nav-link active font-bold" aria-current="page" (click)="redirectTo(item)">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>