import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NewsletterFooterComponent } from './layout/footer/newsletter-footer/newsletter-footer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from './loader/loader.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NewsletterFooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    LoaderModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
