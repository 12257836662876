<section class="footer">
  <div class="footer-one">
    <div class="container">
      <div class="footer-container row">
        <div class="col footer-newsletter">
          <app-newsletter-footer></app-newsletter-footer>
        </div>
        <div class="col footer-info">
          <h2 class="font-bold">CONTACT US</h2>
          <ul class="footer-contact-details">
            <li><a href="tel:+40727337000">+40 727 337 000</a></li>
            <li><a href="mailto:office@kxl.ro">OFFICE@KXL.RO</a></li>
            <li>7th Expozitiei BLVD</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer disclaimer -->
  <div class="footer-second">
    <div class="container">
      <div class="footer-disclaimer-container row">
        <div class="footer-container col-lg-6 col-md-12 col-sm-12">
          <p>© 2020 KXL Urbanism. Toate drepturile rezervate.</p>
        </div>
        <div class="footer-social col-lg-6 col-md-12 col-sm-12">
          <ul>
            <li><i class="fab fa-pinterest-p" aria-hidden="true"></i></li>
            <li><i class="fab fa-twitter" aria-hidden="true"></i></li>
            <li><i class="fab fa-facebook-square" aria-hidden="true"></i></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>