import { NavigationEnd, Router, ActivatedRoute, Params } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  navbarOpen = false;

  menusList: {} = [{
    name: 'home',
    icon: '',
    isActive: true,
    // type: MenuEnums.home,
    url: ''
  }, 
  {
    name: 'portofolio',
    icon: '',
    isActive: true,
    // type: MenuEnums.portofolio,
    url: '/portofolio'
  }];

  @HostListener('window:scroll') onScrollHost(e: Event): void{
    let element = document.querySelector('.navbar');
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('scrolled');
    } else {
      element.classList.remove('scrolled');
    }
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { 
    activatedRoute.params.subscribe((r: Params) => {
        console.log('r', r)
      }
    )
  }

  ngOnInit(): void {
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  redirectTo(item){
    this.router.navigateByUrl(item.url);
    this.navbarOpen = false;
  }

}
