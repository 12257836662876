import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-newsletter-footer',
  templateUrl: './newsletter-footer.component.html',
  styleUrls: ['./newsletter-footer.component.scss']
})
export class NewsletterFooterComponent implements OnInit {
  newsletterForm: FormGroup = null; 
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.generateForm();
  }

  generateForm(): void {
    this.newsletterForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email]))
    })
  }

  sendEmail(): void {
    console.log("send email");
  }

}
